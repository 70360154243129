<template>
  <b-card title="List Holiday">
    <b-col cols="12">
      <b-row class="mb-2">
        <b-col cols="4">
          <b-button
            v-if="permission.add"
            variant="success"
            :to="{ name: 'holiday-management-add' }"
          >
            Add Holiday
          </b-button>
        </b-col>
        <b-col cols="4" />
        <b-col cols="4">
          <b-form-input
            v-model="search"
            type="search"
            placeholder="Type to Search"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-table
            :items="holidays"
            :fields="fields"
            :filter="search"
            :current-page="currentPage"
            :per-page="perPage"
            responsive
          >
            <template #cell(actions)="row">
              <b-dropdown
                id="dropdown-dropleft"
                variant="primary"
                size="sm"
                text="Menu"
                no-caret
              >
                <template #button-content>
                  <feather-icon size="1x" icon="MenuIcon" />
                </template>
                <b-dropdown-item
                  style="text-align:center"
                  v-if="permission.edit"
                >
                  <b-button
                    class="mr-1"
                    variant="outline-primary"
                    @click="toEditHoliday(row.item._id)"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                </b-dropdown-item>
                <b-dropdown-item
                  style="text-align:center"
                  v-if="permission.delete"
                >
                  <b-button
                    @click="deleteHoliday(row)"
                    class="mr-1"
                    variant="danger"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template #cell(holidayDate)="row">
              {{ dates(row.item.holidayDate) }}
              <!-- {{row.item.holidayDate}} -->
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            :total-rows="dataSearch"
            :per-page="perPage"
            v-model="currentPage"
            style="float: right"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "@/axios";
import { dateFormat, userAccess } from "@/utils/utils.js";

export default {
  data() {
    return {
      search: "",
      fields: [
        {
          key: "name",
          label: "Holiday Name",
          filterable: true,
          sortable: true,
        },
        { key: "holidayDate", label: "Holiday Date", filterable: true },
        { key: "actions", label: "Action" },
      ],
      holidays: [],
      currentPage: 1,
      perPage: 10,
    };
  },
  mounted() {
    this.getHolidays();
  },
  methods: {
    dates(date) {
      return dateFormat(date);
    },
    toEditHoliday(id) {
      this.$router.push(`/settings/holiday-management/edit/${id}`);
    },
    getHolidays() {
      axios
        .get("sales-and-purchase/holidays")
        .then((response) => {
          this.holidays = response.data.data;
          console.log({ response });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    deleteHoliday(row) {
      axios
        .delete(`sales-and-purchase/holidays/${row.item._id}`)
        .then((response) => {
          this.getHolidays();
          this.$bvToast.toast(
            `You have successfully deleted a Holiday ${row.item.name} ${row.item.holidayDate}`,
            {
              title: "Success",
              variant: "success",
              solid: true,
            }
          );
        })
        .catch((e) => {
          this.$bvToast.toast(
            `You have failed to delete a Holiday ${row.item.name} ${row.item.holidayDate}`,
            {
              title: "Failed",
              variant: "danger",
              solid: true,
            }
          );
        });
    },
  },
  computed: {
    // ...mapState({
    //   holiday: (state) => state.campaign.campaigns,
    // }),
    permission() {
      return userAccess("Holiday Management", "settings_menu");
    },
    dataSearch() {
      console.log("this.holiday", this.holidays);
      let paginationLength;
      paginationLength = this.holidays.filter((el) => {
        return el.name.includes(this.search);
      });
      if (this.search != "") {
        return paginationLength.length;
      } else {
        return this.holidays.length;
      }
    },
  },
  created() {
    document.title = "Holiday | RSP";
  },
};
</script>
